.gallery-active-slide {
    width: 100vw;
    height: 100vh;
    display: block !important;
}

.gallery-item {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.gallery-slide {
    display: none;
}

.gallery-forward-btn {
    position: absolute;
    right: 0%;
    bottom: 50%;
    transform: translateY(50%);
    width: 10vw;
    height: 50vh;
    z-index: 1;
}

.gallery-backward-btn {
    position: absolute;
    left: 0%;
    bottom: 50%;
    transform: translateY(50%);
    width: 10vw;
    height: 50vh;
    z-index: 1;
}


.gallery-arrow-icon {
    color: rgba(255, 255, 255, 0.5);
    z-index: 10;
    stroke-width: 1.5px;
}

.gallery-close-icon {
    position: absolute;
    top: 0%;
    right: 0%;
    width: 6vw;
    height: 6vh;
    color: rgba(255, 255, 255, 0.5);
    z-index: 10;
    stroke-width: 1.5px;
    margin: 2vh;
}

.gallery-close-icon:hover {
    color: white;
}

.gallery-share-icon {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 6vw;
    height: 6vh;
    color: rgba(255, 255, 255, 0.5);
    z-index: 10;
    stroke-width: 1.5px;
    margin: 2vh;
}

.gallery-share-icon:hover {
    color: white;
}

.gallery-arrow-icon:hover {
    color: white;
}

.gallery-button-icon {
    color: white;
    font-size: 5vw;
}