.choose-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.image-grid {
    margin-top: 3vw;
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    justify-content: space-around;
    grid-gap: 3vw;
}

.image-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.image {
    width: 44vw;
    height: 44vw;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.image:hover {
    cursor: pointer;
    border: 3px solid rgba(0, 119, 221, 1);
}

.choose-image-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    height: 10vh;
}

.choose-image-action {
    color: #fff;
    text-decoration: none;
}

.choose-image-action:hover {
    text-decoration: underline;
}