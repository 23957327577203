.you {
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
    width: 100%;
    border-radius: 5vw;
    -webkit-app-region: drag;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.you-current-image {
    width: 100%;
    height: 100%;
    box-shadow: 25px 25px 50px 0 white inset, -25px -25px 50px 0 white inset;
    border-radius: 5vw;
}

.you-loading-image {
    position: relative;
    width: 90vw;
    height: 90vw;
    border-radius: 5vw;
    background: rgba(32, 32, 32, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@keyframes spinner {
    to {transform: rotate(360deg);}
}

.spinner {
    box-sizing: border-box;
    position: absolute;
    width: 60vw;
    height: 60vw;
    border-radius: 50%;
    border: 3px solid rgba(204, 204, 204, 0);
    border-top-color: rgba(0, 119, 221, 0.5);
    animation: spinner 1s linear infinite;
}

.you-action-buttons {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*gap: 20px;*/
    gap: 5vw;
    z-index: 10000;
    -webkit-app-region: no-drag;
}

.you-action-button {
    background-color: rgb(39, 77, 168);
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.you-action-button-loading {
    background-color: rgb(39, 77, 168);
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes button-spinner {
    to {transform: rotate(360deg);}
}

.button-spinner {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 75%, #ffffff 100%);
    box-sizing: border-box;
    animation: rotation .8s linear infinite;
    z-index: 11;
}
.button-spinner::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    background: rgb(39, 77, 168);
    z-index: 12;
}
@keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg)}
}

.you-action-button-ready {
    background-color: rgba(39, 77, 168);
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.you-action-button-ready > div {
    animation: growAndFade 1.5s infinite ease-out;
    background-color: rgb(39, 77, 168);
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    width: 150%;
    height: 150%;
    border: 2px solid rgba(255, 255, 255, 1);
}

.you-action-button-ready .circle1 {
    animation-delay: 0.25s;
}

.you-action-button-ready .circle2 {
    animation-delay: 0.5s;
}

.you-action-button-ready .circle3 {
    animation-delay: 0.75s;
}

.you-action-button-ready .circle4 {
    animation-delay: 1s;
}

@keyframes growAndFade {
    0% {
        opacity: .5;
        transform: scale(0);
    }
    100% {
        opacity: 0;
        transform: scale(1.10);
    }
}

.you-action-button:hover {
    background-color: rgb(39, 77, 168);
    border: #cccccc 1px solid;
}

.you-action-button-glyph {
    stroke: white;
    stroke-width: 2px;
    width: 60%;
    height: 60%;
}