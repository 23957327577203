.user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    border-radius: 5vw;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.image-gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
            to bottom,
            rgba(32, 32, 32, .8) 0%,
            rgba(32, 32, 32, .4) 8%,
            rgba(32, 32, 32, .0) 20%,
            rgba(32, 32, 32, .0) 80%,
            rgba(32, 32, 32, .4) 92%,
            rgba(32, 32, 32, .8) 100%
    );
    z-index: 10;
    border-radius: 5vw;
}

.animated-image {
    opacity: 0; /* hide the image initially */
    animation: fade-in .5s ease-in-out forwards; /* apply the fade-in animation */
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.user:hover .user-location {
    display: inline-block;
}

.user:hover .user-temp {
    display: inline-block;
}

.image-text {
    color: white;
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 1);
    z-index: 100;
}

.user-name {
    position: absolute;
    top: 0px;
    left: 5px;
    font-weight: 600;
    font-size: 6vw;
}

.user-location {
    position: absolute;
    bottom: 0px;
    left: 5px;
    font-weight: 600;
    font-size: 6vw;
}

.user-temp {
    position: absolute;
    bottom: 0px;
    right: 5px;
    font-weight: 600;
    font-size: 6vw;
}

.user-time {
    position: absolute;
    top: 0px;
    right: 5px;
    font-weight: 600;
    font-size: 6vw;
}

.user-current-image {
    width: 100%;
    height: 100%;
    box-shadow: 25px 25px 50px 0 white inset, -25px -25px 50px 0 white inset;
    border-radius: 5vw;
}