.create-image-container {
    width: 100vw;
    height: 100vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow-y: scroll;
}

.create-image-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.create-image-header h1 {
    font-size: 2.5rem;
}

.create-image-header {
    color: #fff;
}

.create-image-container form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.25rem;
}

.create-image-container fieldset {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    border: none;
}

.title {
    font-weight: bold;
    font-size: 1.6rem;
    color: #fff;
}

.description {
    font-weight: normal;
    font-size: 1.4rem;
    margin-left: 10px;
    color: #fff;
}

.or {
    font-weight: bold;
    font-size: 1.6rem;
    margin: 0 auto;
    color: #fff;
}

.create-image-container input[type="text"],
.create-image-container textarea {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
}

#generate-image,
#cancel {
    background-color: rgba(0, 119, 221, 1);
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 1.2rem;
    padding: 6px 24px;
    cursor: pointer;
}

#generate-image:hover,
#cancel:hover {
    background-color: rgba(0, 119, 221, 1);
}

#cancel {
    background-color: #ccc;
    color: #333;
}

#cancel:hover {
    background-color: #999;
}

.create-image-container .buttons {
    margin-top: 1rem;
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    gap: 10rem;
}
