.create-image-page {
    width: 100vw;
    height: 100vh;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}