.frame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10vw;
    padding-left: 10vw;
    background: transparent;
    height: 100%;
    width: 100%;
    position: relative;
}

.vertical_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

body {
    /*-webkit-app-region: drag;*/
}